.header {
    width: 100%;
    height: 800px;
    justify-content: center;
    align-items: left;
    background-color: #B6BB9B;
    display: flex;
    flex-direction: row;
  }

  .header a {
    border: none;
    justify-content: center;
    background-color: #94987E;
    height: 50px;
    width: 180px;
    color: #F1EEE7;
    font-size: 16px;
    margin-top: 10px;
  }


  .header .leftSide {
    flex: 40%;
    background-image: url('../assets/miluHeader.jpg'); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .header .rightSide {
    flex: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
  }
  .header .rightSide img{
    width: 90%;
  }
  .header p{
    color:#F1EEE7;
    font-family: "PrataRegular";
    font-size: 40px;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media (max-width: 800px) {
    .leftSide .rightSide {
      flex: 100%;
    }
    .header{
      flex-direction: column;
      height: 1600px;
    }

  }
.services {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7C8069;
    flex-direction: column;
    background-image: url('../assets/circulo-background.png'); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .services .title {
    margin: 30px;
    width: 900px
  }
 
  .services .row3 {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .services .row2 {
    display: flex;
    justify-content: center;
    flex-direction: row;
    display: none;
   
  }

  .services .card {
    flex-direction: column;
    flex: 100%;
    text-align: center;
    height: 1000px;
  }
 
  .services h1{
    color:#F1EEE7;
    font-family: "BrokingRegular";
    font-size: 148px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .services img{
    max-width: 100%;  
    height: auto;  
  }
  
  .services h2{
    color:#E5EBDB;
    font-family: "PrataRegular";
    font-size: 40px;
    width: 100%;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
  .services p{
    color:#FFFFFF;
    font-family: "LeagueSpartan";
    font-size: 25px;
    margin-left: 50px;
    margin-right: 50px;
    text-align: justify;
  }
  .services .quote {
    height: auto;
  }

 

  @media only screen and (max-width: 1450px) {
    .services h1{
      font-size: 100px;
    }
    .services h2{
      font-size: 38px;
    }
    .services p{
      font-size: 23px;
    }
    .services .card {
      height: 1000px;
    }
  
  }

  @media only screen and (max-width: 1200px) {
    .services h2{
      font-size: 36px;
    }
    .services p{
      font-size: 22px;
    }
    .services .row3 {
      display: none;
    }
    .services .row2 {
      display: flex;
      justify-content: center;
      flex-direction: row;
    }
    .services .card {
      height: 950px;
    }
    .services .title {
      width: 600px;
    }
    
  }

  @media only screen and (max-width: 920px) {
    .services .card {
      height: 980px;
    }
  
  }

  @media only screen and (max-width: 800px) {
    .services h1{
      font-size: 60px;
    }
    .services .row3 {
      flex-direction: column;
      display: flex;
    }
    .services .row2 {
      display: none;
    }
    .services .title {
      width: 400px
    }
  }
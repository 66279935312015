.aboutmi {
    width: 100%;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: left;
    background-color: #E5EBDB;
    flex-direction: row;
  }

  .aboutmi .leftSide {
    flex: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    background-image: url('../assets/aboutmi.jpg'); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .aboutmi .rightSide {
    flex: 70%;
    height: 100%;
    display: flex;
    
    justify-content: center;
    flex-direction: column;
    background-image: url('../assets/ojo-background.png'); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .aboutmi .title{
    align-items: center;
    padding-left: 30%;
    width: 360px;
  }
  .aboutmi .titleone{
    margin-bottom: 0px;
  }
  
  .aboutmi .rightSide p{
    color:#94987E;
    font-family: "PrataRegular";
    font-size: 26px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .aboutmi h1{
    color:#94987E;
    font-family: "BrokingRegular";
    font-size: 70px;
    margin: 0%;
  }
  @media (max-width: 1050px) {
    .aboutmi{
      height: 1000px;
    }
    .aboutmi .leftSide {
      flex: 40%;
    }
    .aboutmi .rightSide {
      flex: 60%;
    }
    .aboutmi .title{
      padding-left: 20%;
      
    }
  }

  @media (max-width: 800px) {
    .aboutmi{
      flex-direction: column;
      height: 1500px;
    }
    
    .aboutmi p{
      font-size: 24px;
    }
    

  }
  @media (max-width: 400px) {
    .aboutmi .title {
      width: 360px;
    }
    

  }
 
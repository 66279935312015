.values {
  width: 100%;
  height: 1800px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F1EEE7;
  flex-direction: column;
  background-image: url('../assets/plantas1.jpg'); 
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
}

.values .title {
  margin: 30px;
}
.values a {
  border: none;
  justify-content: center;
  background-color: #E5EBDB;
  height: 60px;
  width: 380px;
  color: #7C8069;
  font-size: 16px;
 margin-top: 40px;
  margin-bottom: 50px;
}

.values .row {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content:end;
  flex-direction: row;
  margin-right: 150px;
}
.values .rowLeft {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content:start;
  flex-direction: row;
}

.values .card {
  width: 500px;
  height: 100%;
  background-image: url('../assets/arco-background.png'); 
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 20px;
  margin-right: 20px;

}
.values .product{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.values .circle{
  background-color: #94987E;
  border-radius: 50%;
  height: 65px;
  width: 65px;
  display: flex;
  align-items: center; 
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.values .hidden{
  opacity: 0.3;
  margin-left: 20px;
  margin-right: 20px;

}

.values h2{
  color: #FFFFFF;
  font-family: "PrataRegular";
  text-align: center;    
  margin-top: 150px;
  margin-bottom: 5px;
}
.values h3{
  color: #FFFFFF;
  font-family: "PrataRegular";
  text-align: center;    
  margin-bottom: 10px;
  margin-top: 10px;
}
.values .hh{
  width: 190px;
}
.values h4{
  color: #FFFFFF;
  font-family: "PrataRegular";
  text-align:end;   
  margin-top: 0px; 
  font-size: 14px;
}
.values .hcuatro {
  text-align: center; 
}

.values p{
  color:#FFFFFF;
  font-family: "PrataRegular";
}
@media (max-width: 1440px) {
  .values .card {
    height: 80%;
  }
  .values h2{  
    margin-top: 150px;
  }
}
@media (max-width: 1200px) {
  .values {
    height: 1650px;
  }
  
  .values .title {
    width: 600px;
  }
  .values .row {
    margin-right: 0px;
    
  }
}

@media (max-width: 990px) {
  .values .card {
    height: 70%;
  }
}

@media (max-width: 800px) {
  .values {
    height: 2700px;
  }

  .values .row {
    display: flex;
    flex-direction: column;
    padding: 0px;
    height: 100%;
  }
  .values .rowLeft {
    display: flex;
    flex-direction: column;
    padding: 0px;
    height: 100%;
  }
  .values .card {
    width: 450px;
  }
  .values .title {
    width: 400px;
  }
}
@media (max-width: 450px) {
  .values .title {
    width: 360px;
  }
  .values .card {
    width: 350px;
    height: 350px;
  }
}



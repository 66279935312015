.moreabout {
    width: 100%;
    height: 850px;
    display: flex;
    justify-content: center;
    align-items: left;
    background-color: #F6F2E8;
    flex-direction: row;
  }
  .moreabout .title {
    margin: 20px;
    width: 800px
  }
  .moreabout a {
    border: none;
    justify-content: center;
    background-color: #94987E;
    height: 50px;
    width: 180px;
    color: #F1EEE7;
    font-size: 16px;
    margin-top: 10px;
  }

  .moreabout .leftSide {
    flex: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 5px;
    
  }
  
  .moreabout .rightSide {
    flex: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    background-image: url('../assets/cristal.jpg'); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
 
  .moreabout h1{
    color:#94987E;
    font-family: "BrokingRegular";
    font-size: 34px;
    text-align: center;
    margin-top: 0px;
  }

  .moreabout p{
    color:#94987E;
    font-family: "PrataRegular";
    font-size: 24px;
    text-align: justify;
    padding-left: 5%;
    padding-right: 5%;
  }
  @media (max-width: 1400px) {
    .moreabout .title {
      
      width: 600px
    }

  }
  @media (max-width: 1000px) {
    .moreabout .title {
      
      width: 500px
    }
   
    .moreabout h1{
      font-size: 32px;
    }
    .moreabout p{
      font-size: 22px;
    }

  }
  @media (max-width: 800px) {
    .moreabout .title {
      
      width: 600px
    }
    .leftSide .rightSide {
      flex: 100%;
    }
    .moreabout{
      flex-direction: column;
      height: 1600px;
    }

  }
  @media (max-width: 600px) {
    .moreabout .title {
      
      width: 500px
    }
  }
  @media (max-width: 400px) {
    .moreabout .title {
      
      width: 360px
    }
  }
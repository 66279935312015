.testimonials {
    width: 100%;
    height: 650px;
    display: flex;
    align-items: center;
    background-color: #F1EEE7;
    flex-direction: column;
    justify-content: space-between;
  }
  .testimonials .title {
    width: 900px;
    margin-top: 30px;
  }
 
  .testimonials .stars {
    height: 40px;
    width: 100%;
    background: url("../assets/estrellitas.png") center repeat-x;
    background-size: auto 100%;
  }
 
  .testimonials .row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 5px;
  }

  .testimonials .card {
    height: 230px;
    background-color: #CDD7BB;
    text-align: justify;
    padding: 20px;
    border-radius: 25px;
    margin: 10px;
    position: relative;
  }
  .testimonials .quotation {
    position: absolute;
    top: -40px;
    left: 10px;
    width: 90px;
  }
 
  .testimonials h1{
    color:#94987E;
  }
  .testimonials h2{
    color: #7C8069;
    font-family: "LeagueSpartan";
    text-align: center;    
  }
  .testimonials p{
    color:#FFFFFF;
    font-family: "PrataRegular";
  }
  
  @media (max-width: 1200px) {
    .testimonials .title {
      width: 600px;
    }

  }
  @media (max-width: 1000px) {
   
    .testimonials p{
      font-size: 14px;
    }
    .testimonials h2{
      font-size: 22px;
    }

  }
  @media (max-width: 800px) {
    .testimonials {
      height: 850px;
    }
    .testimonials .row {
      flex-direction: column;
    }
    .testimonials .card {
      height: 140px;
      margin-top: 20px;
    }
    .testimonials .title {
      width: 400px;
    }
    .testimonials .quotation {
      position: absolute;
      top: -25px;
      left: 10px;
      width: 60px;
    }

  }
  @media only screen and (max-width: 460px) {
   
    .testimonials .card {
      height: 190px;
    }
    .testimonials {
      height: 820px;
    }
  }
  @media (max-width: 400px) {
    .testimonials .title {
      width: 360px;
    }
    

  }
.footer {
    width: 100%;
    height: 270px;
    background-color: #7C8069;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 10px;
  }
  .footer .stars {
    height: 40px;
    width: 100%;
    background: url("../assets/estrellitas.png") center repeat-x;
    background-size: auto 100%;
  }
  .footer .row{
    display: flex;
    flex-direction: row;
  }

  .footer .leftSide{
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: left;
    flex-direction: column;
    margin-left: 30px;
  }

  .footer .rightSide{
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: end;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    flex-direction: column;
    margin-right: 30px;
  }

  .footer .contact {
    width: 350px;
  }

  .footer .social {
    width: 100px;
  }
  
  .footer h1 {
    color: #CDD7BB;
  }
  .footer p {
    color: #F1EEE7;
    font-size: 20px;
  }

  @media only screen and (max-width: 800px) {
    .footer .contact {
      width: 250px;
    }
    .footer .social {
      width: 80px;
    }
  }

  @media only screen and (max-width: 650px) {
    .footer .row{
      display: flex;
      flex-direction: column;
    }
   
     .footer .leftSide{
      align-items: center;
      margin-left: 0px;
    }

    .footer .rightSide{
      align-items: center;
      margin-right: 0px;
    }
    .footer {
      height: 300px;
    }
  }
  
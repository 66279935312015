.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "BrokingRegular";
  src: local("BrokingRegular"),
   url("./assets/fonts/Broking/Broking-Regular.ttf") format("truetype");
   font-weight: bold;
 }
 @font-face {
  font-family: "PrataRegular";
  src: local("PrataRegular"),
   url("./assets/fonts/Prata/Prata-Regular.ttf") format("truetype");
   font-weight: bold;
 }

 @font-face {
  font-family: "LeagueSpartan";
  src: local("LeagueSpartan"),
   url("./assets/fonts/League/LeagueSpartan-VariableFont_wght.ttf") format("truetype");
 }